/**
 * @name DOMPurify.ts
 * @description replace all v-html by custom directive that secure content
 */

import { Directive, App, Plugin, DirectiveBinding } from "vue";
import DOMPurify from "dompurify";

/**
 * @name renderSanitize
 * @param {HTMLElement} el
 * @param {DirectiveBinding} binding
 */
const renderSanitize = (el: HTMLElement, binding: DirectiveBinding) => {
  const content = binding.value;
  const sanitizedContent = DOMPurify.sanitize(content, { ADD_ATTR: ["target"] });
  el.innerHTML = sanitizedContent;
};

/**
 * @name DomPurifyDirective
 * @param {HTMLElement} el
 * @param {DirectiveBinding} binding
 * @description Custom directive for Dompurify to replace v-html
 */
const DomPurifyDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    renderSanitize(el, binding);
  },

  updated(el: HTMLElement, binding: DirectiveBinding) {
    renderSanitize(el, binding);
  },
};

/**
 * @name directivePlugin
 * @description Custom directive for Dompurify to replace v-html
 */
const directivePlugin: Plugin = {
  install(app: App) {
    app.directive("sanitize-html", DomPurifyDirective);
  },
};

export default directivePlugin;
