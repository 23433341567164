import { Module, ActionTree, ActionContext, MutationTree, GetterTree } from "vuex";
import { RootState, BuybackOrderStateManagerState } from "@/Models/StoresModel";

/**
 * @name getters
 */
export const getters: GetterTree<BuybackOrderStateManagerState, RootState> = {
  currentStep: function (state: BuybackOrderStateManagerState): string | undefined {
    if (state.currentStep) {
      const step = state.currentStep.charAt(0).toUpperCase() + state.currentStep.slice(1);
      return step;
    }
  },
};

/**
 * @name mutations
 */
export const mutations: MutationTree<BuybackOrderStateManagerState> = {
  /**
   * @name setStep
   * @argument {BuybackOrderStateManagerState} state
   * @argument {string} newStep
   * @description set step in Buyback Estimation front process (only if newStep exist in state.steps list)
   */
  setStep(state: BuybackOrderStateManagerState, newStep: string) {
    console.groupCollapsed(".:: BuybackStepManager | Mutations | setStep ::.");
    console.log(".:: BuybackStepManager | setCurrentStep | New step : %s ::.", newStep);
    console.groupEnd();

    if (state.steps.find((step: string) => step.toLowerCase() === newStep.toLowerCase())) {
      state.currentStep = newStep;
    }
  },

  /**
   * @name goToNextStep
   * @description Set the step marker to the next process step
   */
  goToNextStep(state: BuybackOrderStateManagerState) {
    console.log(".:: BuybackStepManager | Mutations | goToNextStep ::.");
    const currentStepIndex = state.steps.indexOf(state.currentStep);

    console.log(`current step index : ${currentStepIndex}`);
    console.log(`(currentStepIndex + 1 <= this.steps.length)  : ${currentStepIndex + 1 <= state.steps.length}`);
    console.log(`(Next Step  : ${state.steps[currentStepIndex + 1]}`);

    if (currentStepIndex !== -1 || currentStepIndex + 1 <= state.steps.length) {
      state.currentStep = state.steps[currentStepIndex + 1];
    }
  },

  /**
   * @name returnPreviousStep
   * @description Set the step marker to the previous module's step
   */
  returnPreviousStep(state: BuybackOrderStateManagerState) {
    console.log(".:: BuybackStepManager | Mutations | returnPreviousStep ::.");
    const currentStepIndex = state.steps.indexOf(state.currentStep);

    if (currentStepIndex !== -1 || currentStepIndex > 0) {
      state.currentStep = state.steps[currentStepIndex - 1];
    }
  },

  /**
   * @name goToStartSteps
   * @description Set the step marker to the start step
   */
  goToStartSteps(state: BuybackOrderStateManagerState) {
    console.log(".:: BuybackStepManager | Mutations | goToStartSteps ::.");
    state.currentStep = state.startStep;
  },

  /**
   * @name setBrowserDeviceType
   */
  setBrowserDeviceType(state: BuybackOrderStateManagerState, browserInnerWidth: number) {
    state.isMobileDevice = browserInnerWidth < 768;
  },
};

/**
 * @name actions
 */
export const actions: ActionTree<BuybackOrderStateManagerState, RootState> = {
  /**
   * @name setStep
   * @argument {string} step
   * @description update Estimate process step
   */
  setStep(context: ActionContext<BuybackOrderStateManagerState, RootState>, step: string) {
    console.groupCollapsed(".:: BuybackStepManager | Actions | setStep ::.");
    console.log(".:: BuybackStepManager | Actions | setStep | step : %s ::.", step);
    console.groupEnd();

    context.commit("setStep", step);
  },

  /**
   * @name goToNextStep
   * @description Go to Next Process step
   */
  goToNextStep(context: ActionContext<BuybackOrderStateManagerState, RootState>) {
    console.log(".:: BuybackStepManager | Actions | goToNextStep ::.");
    context.commit("goToNextStep");
  },

  /**
   * @name goToNextStep
   * @description Go to Next Process step
   */
  goToPreviousStep(context: ActionContext<BuybackOrderStateManagerState, RootState>) {
    console.log(".:: BuybackStepManager | Actions | goToPreviousStep ::.");
    context.commit("returnPreviousStep");
  },

  /**
   * @name goToStartStep
   * @description Go to Next Process step
   */
  goToStartStep(context: ActionContext<BuybackOrderStateManagerState, RootState>) {
    console.log(".:: BuybackStepManager | Actions | goToStartStep ::.");
    context.commit("goToStartSteps");
  },

  /**
   * @name setBrowserDeviceType
   */
  setBrowserDeviceType(context: ActionContext<BuybackOrderStateManagerState, RootState>, browserInnerWidth: number) {
    context.commit("setBrowserDeviceType", browserInnerWidth);
  },
};

/**
 * @name state
 */
export const state: BuybackOrderStateManagerState = {
  steps: ["estimation", "recapitulation", "customerData"],
  startStep: "estimation",
  currentStep: "",
};

const namespaced = true;

export const StateManager: Module<BuybackOrderStateManagerState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
