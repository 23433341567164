<template>
  <router-view :key="route.path"></router-view>
</template>

<script setup lang="ts">
import { onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { BuybackOrderStateManagerState } from "@/Models/StoresModel";

let browserWidth = window.innerWidth;
const route = useRoute();
const store = useStore();
const stateManagerStoreState = store.state.StateManager as BuybackOrderStateManagerState;
const isMobileDevice = computed(() => stateManagerStoreState.isMobileDevice as boolean);
const setBrowserDeviceType = (browserInnerWidth: number) =>
  store.dispatch("StateManager/setBrowserDeviceType", browserInnerWidth);

/**
 * @watcher
 * @name isMobileDevice
 * @description add a class to body when is detected a mobile display
 */
watch(
  () => isMobileDevice.value,
  () => {
    console.log(".:: App.vue | Watcher | isMobileDevice ::.");
    window.document.querySelector("body")?.classList.toggle("is-mobile-device", isMobileDevice as unknown as boolean);
  },
  { immediate: true },
);

/**
 * LifeCycles
 */
onMounted((): void => {
  console.log(".:: App.vue | LifeHook | Mounted ::.");
  window.addEventListener("resize", onResize);

  onResize();
});

/**
 * @name onResize
 */
const onResize = (): void => {
  browserWidth = window.innerWidth;
  setBrowserDeviceType(browserWidth);
};
</script>

<style lang="scss">
@import "./Assets/scss/main.scss?inline";
</style>
