// import * as Sentry from '@sentry/browser';

export type SentryErr = {
  method: string;
  status_code: number;
  url: string;
  readyState: number;
  response: string;
  responseText: string;
  responseType: string;
  responseURL: string;
  status: number;
  statusText: string;
  timeout: number;
  detail?: string;
};

/**
 * @name validateJson
 * @returns {boolean}
 * @see https://www.lambdatest.com/blog/syntaxerror-json-parse-bad-parsing/
 */
export function validateJson(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * @name throwSentryErr
 * @param {SentryErr} err
 * @description Sometimes s***t happen. So we must to log it in sentry.
 *
 * @todo rework this.
 */
export function throwSentryErr(err: SentryErr): void {
  const isValidResponse = validateJson(err.response);

  if (isValidResponse) {
    // const errResponse = JSON.parse(err.response);
    // Sentry.captureMessage(`.:: ${errResponse.status} | ${errResponse.title} | ${errResponse.detail} ::.`, Sentry.Severity.Error);
  } else {
    // Sentry.captureException(err);
  }
}
