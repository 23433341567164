export type LanguagesAndCountries = {
  code: string /** ISO 639-3 */;
  locale: string /** ISO 639-1 */;
  country: string /** ISO 3166 */;
  name: string;
};

export type DataValidator = {
  country: string;
  regex: { id: string; value: string }[];
  force: { id: string; value: string }[];
};

/**
 * Missing :
 * - China
 * - Hong Kong
 * - Montenegro
 * - Macao
 * - Mauritius
 * - Singapore
 * - Taiwan, Province of China
 */
export const Locales: LanguagesAndCountries[] = [
  { locale: "en", country: "gb", code: "eng", name: "Great Britain" }, // default
  { locale: "en", country: "uk", code: "eng", name: "United Kingdom" },
  { locale: "ca", country: "ad", code: "cat", name: "Andorra" },
  { locale: "fa", country: "af", code: "fas", name: "Afghanistan" },
  { locale: "en", country: "ag", code: "eng", name: "Antigua and Barbuda" },
  { locale: "en", country: "ai", code: "eng", name: "Anguilla" },
  { locale: "sq", country: "al", code: "sqi", name: "Albania" },
  { locale: "hy", country: "am", code: "hye", name: "Armenia" },
  { locale: "pt", country: "ao", code: "por", name: "Angola" },
  { locale: "en", country: "aq", code: "eng", name: "Antarctica" },
  { locale: "es", country: "ar", code: "esp", name: "Argentina" },
  { locale: "en", country: "as", code: "eng", name: "American Samoa" },
  { locale: "de", country: "at", code: "deu", name: "Austria" },
  { locale: "en", country: "au", code: "eng", name: "Australia" },
  { locale: "nl", country: "aw", code: "nld", name: "Aruba" },
  { locale: "sv", country: "ax", code: "swe", name: "Åland Islands" },
  { locale: "az", country: "az", code: "aze", name: "Azerbaijan" },
  { locale: "bs", country: "ba", code: "bos", name: "Bosnia and Herzegovina" },
  { locale: "en", country: "bb", code: "eng", name: "Barbados" },
  { locale: "bn", country: "bd", code: "ben", name: "Bangladesh" },
  { locale: "nl", country: "be", code: "nld", name: "Belgium" },
  { locale: "fr", country: "be", code: "fra", name: "Belgium" },
  { locale: "fr", country: "bf", code: "fra", name: "Burkina Faso" },
  { locale: "bg", country: "bg", code: "bul", name: "Bulgaria" },
  { locale: "ar", country: "bh", code: "ara", name: "Bahrain" },
  { locale: "fr", country: "bi", code: "fra", name: "Burundi" },
  { locale: "fr", country: "bj", code: "fra", name: "Benin" },
  { locale: "fr", country: "bl", code: "fra", name: "Saint Barthélemy" },
  { locale: "en", country: "bm", code: "eng", name: "Bermuda" },
  { locale: "ms", country: "bn", code: "msa", name: "Brunei Darussalam" },
  { locale: "es", country: "bo", code: "esp", name: "Bolivia (Plurinational State of)" },
  { locale: "nl", country: "bq", code: "nld", name: "Bonaire, Sint Eustatius and Saba" },
  { locale: "pt", country: "br", code: "por", name: "Brazil" },
  { locale: "dz", country: "bt", code: "dzo", name: "Bhutan" },
  { locale: "no", country: "bv", code: "nor", name: "Bouvet Island" },
  { locale: "en", country: "bw", code: "eng", name: "Botswana" },
  { locale: "be", country: "by", code: "bel", name: "Belarus" },
  { locale: "en", country: "bz", code: "eng", name: "Belize" },
  { locale: "en", country: "ca", code: "eng", name: "Canada" },
  { locale: "de", country: "ch", code: "deu", name: "Switzerland" },
  { locale: "fr", country: "ch", code: "fra", name: "Switzerland" },
  { locale: "it", country: "ch", code: "ita", name: "Switzerland" },
  { locale: "fr", country: "ci", code: "fra", name: "Côte d'Ivoire" },
  { locale: "es", country: "cl", code: "esp", name: "Chile" },
  { locale: "fr", country: "cm", code: "fra", name: "Cameroon" },
  { locale: "es", country: "co", code: "esp", name: "Colombia" },
  { locale: "es", country: "cr", code: "esp", name: "Costa Rica" },
  { locale: "es", country: "cu", code: "esp", name: "Cuba" },
  { locale: "pt", country: "cv", code: "por", name: "Cabo Verde" },
  { locale: "nl", country: "cw", code: "nld", name: "Curaçao" },
  { locale: "en", country: "cx", code: "eng", name: "Christmas Island" },
  { locale: "el", country: "cy", code: "ell", name: "Cyprus" },
  { locale: "de", country: "de", code: "deu", name: "Germany" },
  { locale: "fr", country: "dj", code: "fra", name: "Djibouti" },
  { locale: "da", country: "dk", code: "dan", name: "Denmark" },
  { locale: "en", country: "dm", code: "eng", name: "Dominica" },
  { locale: "ar", country: "dz", code: "ara", name: "Algeria" },
  { locale: "es", country: "ec", code: "esp", name: "Ecuador" },
  { locale: "et", country: "ee", code: "est", name: "Estonia" },
  { locale: "ar", country: "eg", code: "ara", name: "Egypt" },
  { locale: "ar", country: "eh", code: "ara", name: "Western Sahara" },
  { locale: "ti", country: "er", code: "tir", name: "Eritrea" },
  { locale: "es", country: "es", code: "esp", name: "Spain" },
  { locale: "am", country: "et", code: "amh", name: "Ethiopia" },
  { locale: "fi", country: "fi", code: "fin", name: "Finland" },
  { locale: "en", country: "fj", code: "eng", name: "Fiji" },
  { locale: "en", country: "fm", code: "eng", name: "Micronesia (Federated States of)" },
  { locale: "fr", country: "fr", code: "fra", name: "France" },
  { locale: "fr", country: "ga", code: "fra", name: "Gabon" },
  { locale: "en", country: "gd", code: "eng", name: "Grenada" },
  { locale: "ka", country: "ge", code: "kat", name: "Georgia" },
  { locale: "fr", country: "gf", code: "fra", name: "French Guiana" },
  { locale: "en", country: "gg", code: "eng", name: "Guernsey" },
  { locale: "en", country: "gh", code: "eng", name: "Ghana" },
  { locale: "en", country: "gi", code: "eng", name: "Gibraltar" },
  { locale: "kl", country: "gl", code: "kal", name: "Greenland" },
  { locale: "fr", country: "gn", code: "fra", name: "Guinea" },
  { locale: "fr", country: "gp", code: "fra", name: "Guadeloupe" },
  { locale: "es", country: "gq", code: "esp", name: "Equatorial Guinea" },
  { locale: "el", country: "gr", code: "ell", name: "Greece" },
  { locale: "en", country: "gs", code: "eng", name: "South Georgia and the South Sandwich Islands" },
  { locale: "es", country: "gt", code: "esp", name: "Guatemala" },
  { locale: "en", country: "gu", code: "eng", name: "Guam" },
  { locale: "pt", country: "gw", code: "por", name: "Guinea-Bissau" },
  { locale: "en", country: "gy", code: "eng", name: "Guyana" },
  { locale: "es", country: "hn", code: "esp", name: "Honduras" },
  { locale: "hr", country: "hr", code: "hrv", name: "Croatia" },
  { locale: "fr", country: "ht", code: "fra", name: "Haiti" },
  { locale: "hu", country: "hu", code: "hun", name: "Hungary" },
  { locale: "id", country: "id", code: "ind", name: "Indonesia" },
  { locale: "en", country: "ie", code: "eng", name: "Ireland" },
  { locale: "he", country: "il", code: "heb", name: "Israel" },
  { locale: "en", country: "im", code: "eng", name: "Isle of Man" },
  { locale: "hi", country: "in", code: "hin", name: "India" },
  { locale: "ar", country: "iq", code: "ara", name: "Iraq" },
  { locale: "fa", country: "ir", code: "fas", name: "Iran (Islamic Republic of)" },
  { locale: "is", country: "is", code: "isl", name: "Iceland" },
  { locale: "it", country: "it", code: "ita", name: "Italy" },
  { locale: "en", country: "je", code: "eng", name: "Jersey" },
  { locale: "en", country: "jm", code: "eng", name: "Jamaica" },
  { locale: "ar", country: "jo", code: "ara", name: "Jordan" },
  { locale: "ja", country: "jp", code: "jpn", name: "Japan" },
  { locale: "sw", country: "ke", code: "swa", name: "Kenya" },
  { locale: "ky", country: "kg", code: "kir", name: "Kyrgyzstan" },
  { locale: "km", country: "kh", code: "khm", name: "Cambodia" },
  { locale: "ko", country: "kp", code: "kor", name: "North Korea" },
  { locale: "ko", country: "kr", code: "kor", name: "South Korea" },
  { locale: "en", country: "ki", code: "eng", name: "Kiribati" },
  { locale: "en", country: "kn", code: "eng", name: "Saint Kitts and Nevis" },
  { locale: "ar", country: "kw", code: "ara", name: "Kuwait" },
  { locale: "kk", country: "kz", code: "kaz", name: "Kazakhstan" },
  { locale: "ar", country: "lb", code: "ara", name: "Lebanon" },
  { locale: "en", country: "lc", code: "eng", name: "Saint Lucia" },
  { locale: "de", country: "li", code: "deu", name: "Liechtenstein" },
  { locale: "si", country: "lk", code: "sin", name: "Sri Lanka" },
  { locale: "en", country: "lr", code: "eng", name: "Liberia" },
  { locale: "en", country: "ls", code: "eng", name: "Lesotho" },
  { locale: "lt", country: "lt", code: "lit", name: "Lithuania" },
  { locale: "lb", country: "lu", code: "ltz", name: "Luxembourg" },
  { locale: "lv", country: "lv", code: "lav", name: "Latvia" },
  { locale: "ar", country: "ly", code: "ara", name: "Libya" },
  { locale: "fr", country: "ma", code: "fra", name: "Morocco" },
  { locale: "fr", country: "mc", code: "fra", name: "Monaco" },
  { locale: "fr", country: "mf", code: "fra", name: "Saint Martin (French part)" },
  { locale: "mg", country: "mg", code: "mlg", name: "Madagascar" },
  { locale: "fr", country: "ml", code: "fra", name: "Mali" },
  { locale: "my", country: "mm", code: "mya", name: "Myanmar" },
  { locale: "mn", country: "mn", code: "mon", name: "Mongolia" },
  { locale: "fr", country: "mq", code: "fra", name: "Martinique" },
  { locale: "ar", country: "mr", code: "ara", name: "Mauritania" },
  { locale: "en", country: "ms", code: "eng", name: "Montserrat" },
  { locale: "mt", country: "mt", code: "mlt", name: "Malta" },
  { locale: "dv", country: "mv", code: "div", name: "Maldives" },
  { locale: "en", country: "mw", code: "eng", name: "Malawi" },
  { locale: "es", country: "mx", code: "esp", name: "Mexico" },
  { locale: "ms", country: "my", code: "msa", name: "Malaysia" },
  { locale: "pt", country: "mz", code: "por", name: "Mozambique" },
  { locale: "en", country: "na", code: "eng", name: "Namibia" },
  { locale: "fr", country: "nc", code: "fra", name: "New Caledonia" },
  { locale: "en", country: "nf", code: "eng", name: "Norfolk Island" },
  { locale: "en", country: "ng", code: "eng", name: "Nigeria" },
  { locale: "es", country: "ni", code: "esp", name: "Nicaragua" },
  { locale: "nb", country: "no", code: "nob", name: "Norway" },
  { locale: "ne", country: "np", code: "nep", name: "Nepal" },
  { locale: "na", country: "nr", code: "nau", name: "Nauru" },
  { locale: "en", country: "nu", code: "eng", name: "Niue" },
  { locale: "mi", country: "nz", code: "mri", name: "New Zealand" },
  { locale: "ar", country: "om", code: "ara", name: "Oman" },
  { locale: "es", country: "pa", code: "esp", name: "Panama" },
  { locale: "es", country: "pe", code: "esp", name: "Peru" },
  { locale: "fr", country: "pf", code: "fra", name: "French Polynesia" },
  { locale: "en", country: "pg", code: "eng", name: "Papua New Guinea" },
  { locale: "en", country: "pk", code: "eng", name: "Pakistan" },
  { locale: "pl", country: "pl", code: "pol", name: "Poland" },
  { locale: "fr", country: "pm", code: "fra", name: "Saint Pierre and Miquelon" },
  { locale: "en", country: "pn", code: "eng", name: "Pitcairn" },
  { locale: "es", country: "pr", code: "esp", name: "Puerto Rico" },
  { locale: "ar", country: "ps", code: "ara", name: "Palestine, State of" },
  { locale: "pt", country: "pt", code: "por", name: "Portugal" },
  { locale: "en", country: "pw", code: "eng", name: "Palau" },
  { locale: "es", country: "py", code: "esp", name: "Paraguay" },
  { locale: "ar", country: "qa", code: "ara", name: "Qatar" },
  { locale: "fr", country: "re", code: "fra", name: "Réunion" },
  { locale: "ro", country: "ro", code: "ron", name: "Romania" },
  { locale: "sr", country: "rs", code: "srp", name: "Serbia" },
  { locale: "ru", country: "ru", code: "rus", name: "Russia" },
  { locale: "rw", country: "rw", code: "kin", name: "Rwanda" },
  { locale: "ar", country: "sa", code: "ara", name: "Saudi Arabia" },
  { locale: "en", country: "sb", code: "eng", name: "Solomon Islands" },
  { locale: "fr", country: "sc", code: "fra", name: "Seychelles" },
  { locale: "sv", country: "se", code: "swe", name: "Sweden" },
  { locale: "en", country: "sh", code: "eng", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { locale: "sl", country: "si", code: "slv", name: "Slovenia" },
  { locale: "no", country: "sj", code: "nor", name: "Svalbard and Jan Mayen" },
  { locale: "sk", country: "sk", code: "slk", name: "Slovakia" },
  { locale: "en", country: "sl", code: "eng", name: "Sierra Leone" },
  { locale: "it", country: "sm", code: "ita", name: "Republic of San Marino" },
  { locale: "fr", country: "sn", code: "fra", name: "Senegal" },
  { locale: "so", country: "so", code: "som", name: "Somalia" },
  { locale: "nl", country: "sr", code: "nld", name: "Suriname" },
  { locale: "en", country: "ss", code: "eng", name: "South Sudan" },
  { locale: "pt", country: "st", code: "por", name: "Sao Tome and Principe" },
  { locale: "es", country: "sv", code: "esp", name: "El Salvador" },
  { locale: "nl", country: "sx", code: "nld", name: "Sint Maarten (Dutch part)" },
  { locale: "ar", country: "sy", code: "ara", name: "Syrian Arab Republic" },
  { locale: "fr", country: "td", code: "fra", name: "Chad" },
  { locale: "fr", country: "tg", code: "fra", name: "Togo" },
  { locale: "th", country: "th", code: "tha", name: "Thailand" },
  { locale: "tg", country: "tj", code: "tgk", name: "Tajikistan" },
  { locale: "en", country: "tk", code: "eng", name: "Tokelau" },
  { locale: "pt", country: "tl", code: "por", name: "Timor-Leste" },
  { locale: "tk", country: "tm", code: "tuk", name: "Turkmenistan" },
  { locale: "ar", country: "tn", code: "ara", name: "Tunisia" },
  { locale: "en", country: "to", code: "eng", name: "Tonga" },
  { locale: "tr", country: "tr", code: "tur", name: "Turkey" },
  { locale: "en", country: "tt", code: "eng", name: "Trinidad and Tobago" },
  { locale: "en", country: "tv", code: "eng", name: "Tuvalu" },
  { locale: "sw", country: "tz", code: "swa", name: "United Republic of Tanzania" },
  { locale: "uk", country: "ua", code: "ukr", name: "Ukraine" },
  { locale: "en", country: "ug", code: "eng", name: "Uganda" },
  { locale: "en", country: "us", code: "eng", name: "United States of America" },
  { locale: "es", country: "uy", code: "esp", name: "Uruguay" },
  { locale: "uz", country: "uz", code: "uzb", name: "Uzbekistan" },
  { locale: "en", country: "vc", code: "eng", name: "Saint Vincent and the Grenadines" },
  { locale: "es", country: "ve", code: "esp", name: "Venezuela (Bolivarian Republic of)" },
  { locale: "en", country: "vg", code: "eng", name: "Virgin Islands (British)" },
  { locale: "en", country: "vi", code: "eng", name: "Virgin Islands (U.S.)" },
  { locale: "vi", country: "vn", code: "vie", name: "Vietnam" },
  { locale: "bi", country: "vu", code: "bis", name: "Vanuatu" },
  { locale: "fr", country: "wf", code: "fra", name: "Wallis and Futuna" },
  { locale: "sm", country: "ws", code: "smo", name: "Samoa" },
  { locale: "ar", country: "ye", code: "ara", name: "Yemen" },
  { locale: "fr", country: "yt", code: "fra", name: "Mayotte" },
  { locale: "en", country: "za", code: "eng", name: "South Africa" },
  { locale: "en", country: "zm", code: "eng", name: "Zambia" },
  { locale: "en", country: "zw", code: "eng", name: "Zimbabwe" },
  { locale: "en", country: "sz", code: "eng", name: "Eswatini" },
  { locale: "mk", country: "mk", code: "mkd", name: "North Macedonia" },
  { locale: "en", country: "ph", code: "eng", name: "Philippines" },
  { locale: "nl", country: "nl", code: "nld", name: "Netherlands" },
  { locale: "ar", country: "ae", code: "ara", name: "United Arab Emirates" },
  { locale: "ro", country: "md", code: "ron", name: "Republic of Moldova" },
  { locale: "en", country: "gm", code: "eng", name: "Gambia" },
  { locale: "es", country: "do", code: "esp", name: "Dominican Republic" },
  { locale: "ar", country: "sd", code: "ara", name: "Sudan" },
  { locale: "lo", country: "la", code: "lao", name: "Lao People's Democratic Republic" },
  { locale: "fr", country: "cg", code: "fra", name: "Republic of the Congo" },
  { locale: "cs", country: "cz", code: "ces", name: "Czechia" },
  { locale: "fr", country: "ne", code: "fra", name: "Niger" },
  { locale: "fr", country: "cd", code: "fra", name: "Democratic Republic of the Congo" },
  { locale: "en", country: "bs", code: "eng", name: "Commonwealth of The Bahamas" },
  { locale: "en", country: "cc", code: "eng", name: "Cocos (Keeling) Islands" },
  { locale: "fr", country: "cf", code: "fra", name: "Central African Republic" },
  { locale: "en", country: "ck", code: "eng", name: "Cook Islands" },
  { locale: "en", country: "fk", code: "eng", name: "Falkland Islands" },
  { locale: "da", country: "fo", code: "dan", name: "Faroe Islands" },
  { locale: "en", country: "hm", code: "eng", name: "Territory of Heard Island and McDonald Islands" },
  { locale: "en", country: "io", code: "eng", name: "British Indian Ocean Territory" },
  { locale: "fr", country: "km", code: "fra", name: "Comoros" },
  { locale: "en", country: "ky", code: "eng", name: "Cayman Islands" },
  { locale: "en", country: "mh", code: "eng", name: "Republic of the Marshall Islands" },
  { locale: "en", country: "mp", code: "eng", name: "Commonwealth of the Northern Mariana Islands" },
  { locale: "en", country: "tc", code: "eng", name: "Turks and Caicos Islands" },
  { locale: "fr", country: "tf", code: "fra", name: "French Southern and Antarctic Lands" },
  { locale: "en", country: "um", code: "eng", name: "United States Minor Outlying Islands" },
  { locale: "la", country: "va", code: "lat", name: "Holy See" },
  { locale: "sq", country: "xk", code: "sqi", name: "Republic of Kosovo" },
  { locale: "nl", country: "an", code: "nld", name: "Netherlands Antilles " },
];
