import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState, OrderStoreState } from "@/Models/StoresModel";
import {
  Product,
  Quote,
  Promotion,
  Quotation,
  Order,
  Customer,
  Address,
  OrderOption,
  Processes,
} from "@recommerce/buyback-order-sdk";
import { FormElementModel } from "@/Models/FormElementModel";
import { Referrer } from "@/Models/BuybackModel";
import { ActionContext } from "vuex/types/index.js";

/**
 * Local types
 *
 * @name OrderField
 */
type OrderField = {
  field: string;
  value: string | number;
};

/**
 * reset State
 */
const initialState = (): OrderStoreState => {
  console.log(".:: BuybackOrder | initialState ::.");

  return {
    order: {} as Order,
    customer: {} as Customer,
    product: {} as Product,
    quotation: {} as Quotation,
    quote: {} as Quote,
    address: {} as Address,
    promotions: [] as Promotion[],
    referrer: "",
    searchTerms: "",
  };
};

/**
 * @name getters
 */
export const getters: GetterTree<OrderStoreState, RootState> = {
  /**
   * @name processes
   * @param state
   * @returns {OrderOption[]} processes
   */
  processes: (state: OrderStoreState): OrderOption[] => {
    const processes = state.quotation.availableProcesses;
    const cleanProcesses: OrderOption[] = [];

    if (processes) {
      processes.forEach(process => {
        for (const [key, value] of Object.entries(process)) {
          if (Array.isArray(value)) {
            const isValid = key !== "payment" ? true : false;

            Object.defineProperties(value[0], {
              name: {
                value: key,
                writable: true,
              },

              isValid: {
                value: isValid,
                writable: true,
              },
            });

            cleanProcesses.push(value[0]);
          }
        }
      });
    }

    return cleanProcesses;
  },

  customerEmail: (state: OrderStoreState): string => {
    return state.customer.email;
  },
};

/**
 * @name mutations
 */
export const mutations: MutationTree<OrderStoreState> = {
  /**
   * @name updateSearchTerms
   * @argument { string } terms
   */
  updateSearchTerms(state: OrderStoreState, terms: string) {
    console.log(".:: BuybackOrder | Mutations | updateSearchTerms ::.");
    state.searchTerms = terms;
  },

  /**
   * @name updateProduct
   * @argument { Product } product
   */
  updateProduct(state: OrderStoreState, product: Product) {
    console.groupCollapsed(".:: BuybackOrder | Mutations | updateProduct ::.");
    console.log(".:: BuybackOrder | updateProduct | product : %s ::.", product);
    console.groupEnd();

    state.product = product;
  },

  /**
   * @name updateCustomerData
   * @argument { FormElementModel } customerData
   */
  updateCustomerData(state: OrderStoreState, customerData: FormElementModel) {
    console.log(".:: BuybackOrder | Mutations | updateCustomerData ::.");
    state.customer = { ...state.customer, [customerData.id]: customerData.value };
  },

  /**
   * @name updateCustomerIban
   * @argument iban
   */
  updateCustomerIban(state: OrderStoreState, iban: string) {
    console.log(".:: BuybackOrder | Mutations | updateCustomerIban ::.");
    state.order.payment = state.order.payment ?? ({} as OrderOption);
    state.order.payment["iban"] = iban;
  },

  /**
   * @name updateCustomerBic
   * @argument bic
   */
  updateCustomerBic(state: OrderStoreState, bic: string) {
    console.log(".:: BuybackOrder | Mutations | updateCustomerBic ::.");
    state.order.payment = state.order.payment ?? ({} as OrderOption);
    state.order.payment["bic"] = bic;
  },

  /**
   * @name updateTransportationMode
   * @argument {OrderOption} transportation
   */
  updateTransportationMode(state: OrderStoreState, transportation: OrderOption) {
    console.log(".:: BuybackOrder | Mutations | updateTransportationMode ::.");
    state.order.transportation = transportation;
  },

  /**
   * @name updateCustomerAccountNumber
   * @argument accountNumber
   */
  updateCustomerAccountNumber(state: OrderStoreState, accountNumber: string) {
    console.log(".:: BuybackOrder | Mutations | updateCustomerAccountNumber ::.");
    state.order.payment = state.order.payment ?? ({} as OrderOption);
    state.order.payment["accountNumber"] = accountNumber;
  },

  /**
   * @name updateCustomerSortCode
   * @argument accountNumber
   */
  updateCustomerSortCode(state: OrderStoreState, sortCode: string) {
    console.log(".:: BuybackOrder | Mutations | updateCustomerSortCode ::.");
    state.order.payment = state.order.payment ?? ({} as OrderOption);
    state.order.payment["sortCode"] = sortCode;
  },

  /**
   * @name updateAddressData
   * @argument { FormElementModel } addressData
   */
  updateAddressData(state: OrderStoreState, addressData: FormElementModel) {
    console.log(".:: BuybackOrder | Mutations | updateAddressData ::.");
    state.address = { ...state.address, [addressData.id]: addressData.value };
  },

  /**
   * @name updateQuotation
   * @argument state
   * @argument { Quotation } quotation
   */
  updateQuotation(state: OrderStoreState, quotation: Quotation) {
    console.groupCollapsed(".:: BuybackOrder | Mutations | updateQuotation ::.");
    console.log(".:: BuybackOrder | updateQuotation | quotation : %s ::.", quotation);
    console.groupEnd();

    state.quotation = quotation;
  },

  /**
   * @name updateOrderOptions
   * @description set process id in BuybackOrder.order
   */
  updateOrderOptions(state: OrderStoreState, processName: string) {
    console.log(".:: BuybackOrder | Mutations | updateOrderOptions ::.");
    const processes = state.quotation.availableProcesses;

    if (state.order) {
      processes.forEach(process => {
        for (const [key, value] of Object.entries(process)) {
          if (Array.isArray(value) && key === processName) {
            if (!state.order[key as keyof Order]) {
              const obj = { id: value[0]["id"], tag: value[0]["tag"], title: value[0]["title"], value: value[0].value };
              state.order = { ...state.order, [key]: obj };
            }
          }
        }
      });

      state.order["process"] = state.quotation.availableProcesses[0].id.toString();
    }
  },

  /**
   * @name updateOrder
   * @argument { OrderField } datas
   * @description set process id in BuybackOrder.order
   */
  updateOrder(state: OrderStoreState, datas: OrderField[]) {
    console.groupCollapsed(".:: BuybackOrder | Mutations | updateOrder ::.");

    for (const index in datas) {
      console.log(`.:: BuybackOrder | Mutations | updateOrder | field : ${datas[index].field}::.`);
      console.log(`.:: BuybackOrder | Mutations | updateOrder | field : ${datas[index].value}::.`);
    }
    console.groupEnd();

    for (const index in datas) {
      state.order = { ...state.order, [datas[index].field]: datas[index].value };
    }
  },

  /**
   * @name updateRestituedOrder
   * @argument { Order } order
   * @description set complete restitued order in BuybackOrder.order
   */
  updateRestituedOrder(state: OrderStoreState, order: Order) {
    console.groupCollapsed(".:: BuybackOrder | Mutations | updateRestituedOrder ::.");
    console.log(order);

    state.order = order;
  },

  /**
   * @name updateQuote
   * @argument {Quote} quote
   */
  updateQuote(state: OrderStoreState, quote: Quote) {
    console.groupCollapsed(".:: BuybackOrder | Mutations | updateQuote ::.");
    console.log(".:: BuybackOrder | updateQuote | New quote : %s ::.", quote);
    console.groupEnd();

    state.quote = quote;
  },

  /**
   * @name resetQuote
   * @argument state
   */
  resetQuote(state: OrderStoreState) {
    console.log(".:: BuybackOrder | Mutations | resetQuote ::.");

    state.quote = {} as Quote;
  },

  /**
   * @name updatePromotion
   * @argument state
   * @argument promotion
   */
  updatePromotion(state: OrderStoreState, promotion: Promotion) {
    console.groupCollapsed(".:: BuybackOrder | Mutations | updatePromotion ::.");
    console.log(".:: BuybackOrder | updatePromotion | New promotion : %s ::.", promotion);
    console.groupEnd();

    if (Object.keys(promotion).length > 0) {
      state.promotions = [promotion];
    } else {
      state.promotions = [];
    }
  },

  /**
   * @name updateReferrer
   * @argument state
   * @argument promotion
   */
  updateReferrer(state: OrderStoreState, referrer: Referrer) {
    console.groupCollapsed(".:: BuybackOrder | Mutations | updateReferrer ::.");
    console.log(".:: BuybackOrder | updateReferrer | referrer : %s ::.", referrer.value);
    console.groupEnd();
    state.referrer = referrer.value;
  },

  /**
   * @name resetStore
   * @argument state
   */
  resetStore(state: OrderStoreState) {
    console.log(".:: BuybackOrder | Mutations | resetStore ::.");
    Object.assign(state, initialState());
  },

  /**
   * @name resetStoreAfterPost
   * @argument state
   */
  resetStoreAfterPost(state: OrderStoreState) {
    console.log(".:: BuybackOrder | Mutations | resetStoreAfterPost ::.");
    Object.assign(state.quotation, {} as Quotation);
  },

  /**
   * @name removeReferrer
   * @argument state
   */
  removeReferrer(state: OrderStoreState) {
    console.log(".:: BuybackOrder | Mutations | removeReferrer ::.");
    if ("referrer" in state) {
      delete state.referrer;
    }
  },
};

/**
 * @name actions
 */
export const actions: ActionTree<OrderStoreState, RootState> = {
  /**
   * @name setSearchTerms
   * @argument { string } terms
   */
  setSearchTerms(context: ActionContext<OrderStoreState, RootState>, terms: string) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setSearchTerms ::.");
    console.log(".:: BuybackOrder | Actions | setProduct | terms : %s ::.", terms);
    console.groupEnd();

    context.commit("updateSearchTerms", terms);
  },

  /**
   * @name setProduct
   * @argument { Product } product
   */
  setProduct(context: ActionContext<OrderStoreState, RootState>, product: Product) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setProduct ::.");
    console.log(".:: BuybackOrder | Actions | setProduct | product : %s ::.", product);
    console.groupEnd();

    context.commit("updateProduct", product);
  },

  /**
   * @name setQuote
   * @argument { Quote } quote
   */
  setQuote(context: ActionContext<OrderStoreState, RootState>, quote: Quote) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setQuote ::.");
    console.log(".:: BuybackOrder | Actions | setQuote | quote : %s ::.", quote);
    console.groupEnd();

    context.commit("updateQuote", quote);
  },

  /**
   * @name resetQuote
   */
  resetQuote(context: ActionContext<OrderStoreState, RootState>) {
    console.log(".:: BuybackOrder | Actions | resetQuote ::.");

    context.commit("resetQuote");
  },

  /**
   * @name setPromotion
   * @argument { Promotion } promotion
   */
  setPromotion(context: ActionContext<OrderStoreState, RootState>, promotion: Promotion) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setPromotion ::.");
    console.log(".:: BuybackOrder | Actions | setPromotion | promotion : %s ::.", promotion);
    console.groupEnd();

    context.commit("updatePromotion", promotion);
  },

  /**
   * @name resetPromotion
   * @argument { Promotion } promotion
   */
  resetPromotion(context: ActionContext<OrderStoreState, RootState>) {
    console.log(".:: BuybackOrder | Actions | resetPromotion ::.");
    context.commit("updatePromotion", {} as Promotion);
  },

  /**
   * @name setUserData
   * @argument { FormElementModel } userdata
   */
  setUserData(context: ActionContext<OrderStoreState, RootState>, userdata: FormElementModel) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setUserData ::.");
    console.log(".:: BuybackOrder | Actions | setUserData | userdata ::.");
    console.log(userdata);
    console.groupEnd();

    let ressource = userdata.ressource;
    ressource = ressource.charAt(0).toUpperCase() + ressource.slice(1);
    context.commit("update" + ressource + "Data", userdata);
  },

  /**
   * @name setUserIban
   */
  setUserIban(context: ActionContext<OrderStoreState, RootState>, iban: string) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setUserIban ::.");
    console.log(".:: BuybackOrder | Actions | setUserIban | Iban ::.");
    console.log(iban);
    console.groupEnd();

    context.commit("updateCustomerIban", iban);
  },

  /**
   * @name setUserAccountNumber
   */
  setUserAccountNumber(context: ActionContext<OrderStoreState, RootState>, accountNumber: string) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setUserAccountNumber ::.");
    console.log(".:: BuybackOrder | Actions | setUserAccountNumber | Iban ::.");
    console.log(accountNumber);
    console.groupEnd();

    context.commit("updateCustomerAccountNumber", accountNumber);
  },

  /**
   * @name setUserSortCode
   */
  setUserSortCode(context: ActionContext<OrderStoreState, RootState>, sortCode: string) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setUserSortCode ::.");
    console.log(".:: BuybackOrder | Actions | setUserSortCode | Iban ::.");
    console.log(sortCode);
    console.groupEnd();

    context.commit("updateCustomerSortCode", sortCode);
  },

  /**
   * @name setUserBic
   */
  setUserBic(context: ActionContext<OrderStoreState, RootState>, bic: string) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setUserBic ::.");
    console.log(".:: BuybackOrder | Actions | setUserBic | Bic ::.");
    console.log(bic);
    console.groupEnd();

    context.commit("updateCustomerBic", bic);
  },

  /**
   * @name setTransportationMode
   * @argument { OrderOption } transportation
   */
  setTransportationMode(context: ActionContext<OrderStoreState, RootState>, transportation: OrderOption) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setTransportationMode ::.");
    console.log(transportation);
    console.groupEnd();

    context.commit("updateTransportationMode", transportation);
  },

  /**
   * @name setQuotation
   * @argument { Quotation } quotation
   */
  setQuotation(context: ActionContext<OrderStoreState, RootState>, payload: { quotation: Quotation; process: string }) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setQuotation ::.");
    console.log(payload.quotation);
    console.log(payload.process);
    console.groupEnd();

    if (payload.process.length > 0) {
      payload.quotation.availableProcesses = payload.quotation.availableProcesses.filter((prc: Processes) => {
        return prc.label === payload.process;
      });
    } else {
      payload.quotation.availableProcesses = payload.quotation.availableProcesses.slice(0, 1);
    }

    context.commit("updateQuotation", payload.quotation);

    const dataObj = [{ field: "quotation", value: payload.quotation.id }];
    context.commit("updateOrder", dataObj);
    context.commit("updateOrderOptions", "payment");
  },

  /**
   * @name updateOrderOptions
   */
  updateOrderOptions(context: ActionContext<OrderStoreState, RootState>, processName: string) {
    console.log(".:: BuybackOrder | Actions | updateOrderOptions ::.");

    context.commit("updateOrderOptions", processName);
  },

  /**
   * @name updateOrderCustomer
   * @argument { number } customerId
   */
  updateOrderCustomer(context: ActionContext<OrderStoreState, RootState>, customerId: string) {
    console.log(".:: BuybackOrder | Actions | updateOrderCustomer ::.");

    const dataObj = { field: "customer", id: "id", value: customerId };
    context.commit("updateOrder", [dataObj]);
    context.commit("updateCustomerData", dataObj);
  },

  /**
   * @name updateOrderAddress
   * @argument { number } addressId
   */
  updateOrderAddress(context: ActionContext<OrderStoreState, RootState>, addressId: string) {
    console.log(".:: BuybackOrder | Actions | updateOrderAddress ::.");

    const dataObj = [
      { field: "shipAddress", id: "id", value: addressId },
      { field: "billAddress", id: "id", value: addressId },
    ];
    context.commit("updateOrder", dataObj);
    context.commit("updateAddressData", { id: "id", value: addressId });
  },

  /**
   * @name setOrderStore
   * @argument { Order } order
   * @TODO this method not only set orderID.
   */
  setOrderStore(context: ActionContext<OrderStoreState, RootState>, order: Order) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setOrderStore ::.");
    console.log(`.:: BuybackOrder | Actions | setOrderStore : ${order.id} ::.`);
    console.groupEnd();

    /**
     * Setting id, voucher url, barcode, promotions document required
     */
    const dataObj = [
      { field: "id", id: "id", value: order.id },
      { field: "barcode", id: "id", value: order.barcode },
      { field: "identityCheck", id: "id", value: order.identityCheck },
      { field: "transportation", id: "id", value: order.transportation },
    ];

    if ("docToPrint" in order._links) {
      dataObj.push({ field: "docToPrint", id: "id", value: order._links.docToPrint.href });
    }

    context.commit("updateOrder", dataObj);

    if (order._embedded.declaredItems[0].promotions.length > 0) {
      context.commit("updatePromotion", order._embedded.declaredItems[0].promotions[0]);
    }
  },

  /**
   * @name setRestituedOrderStore
   * @argument { Order } order
   * @description set order in store when order is called from outside estimation process
   */
  setRestituedOrderStore(context: ActionContext<OrderStoreState, RootState>, order: Order) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setRestituedOrderStore ::.");
    console.log(`.:: BuybackOrder | Actions | setRestituedOrderStore : ${order.id} ::.`);
    console.groupEnd();

    context.commit("updateRestituedOrder", order);
  },

  /**
   * @name resetOrder
   */
  resetOrder(context: ActionContext<OrderStoreState, RootState>) {
    console.log(".:: BuybackOrder | Actions | resetOrder ::.");
    context.commit("resetStore");
  },

  /**
   * @name setReferrer
   */
  setReferrer(context: ActionContext<OrderStoreState, RootState>, referrer: Referrer) {
    console.groupCollapsed(".:: BuybackOrder | Actions | setReferrer ::.");
    console.log(`.:: BuybackOrder | Actions | setReferrer : ${referrer} ::.`);
    console.groupEnd();

    context.commit("updateReferrer", referrer);
  },

  /**
   * @name removeReferrer
   */
  removeReferrer(context: ActionContext<OrderStoreState, RootState>) {
    console.log(".:: BuybackOrder | Actions | removeReferrer ::.");

    context.commit("removeReferrer");
  },

  /**
   * @name resetStoreAfterPost
   */
  resetStoreAfterPost(context: ActionContext<OrderStoreState, RootState>) {
    console.log(".:: BuybackOrder | Actions | resetStoreAfterPost ::.");

    context.commit("updateQuotation", {} as Quotation);
    context.commit("updateQuote", {} as Quote);
    context.commit("updatePromotion", {} as Promotion);

    const dataObj = [
      { field: "quotation", value: "" },
      { field: "transportation", value: "" },
      { field: "payment", value: "" },
      { field: "process", value: "" },
      { field: "customer", value: "" },
      { field: "shipAddress", value: "" },
      { field: "billAddress", value: "" },
      { field: "barcode", value: "" },
      { field: "id", value: "" },
      { field: "docToPrint", value: "" },
    ];

    context.commit("updateOrder", dataObj);
  },
};

/**
 * @name state
 */
export const state: OrderStoreState = initialState();

const namespaced = true;

export const OrderStore: Module<OrderStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
