import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/Config/Routes/router";
import store from "@/Stores/index";
import * as Sentry from "@sentry/vue";
import Gtm from "@gtm-support/vue-gtm";
import i18n from "@/Config/i18n/i18n";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import DomPurifyDirective from "@/Utils/Dompurify";

const app = createApp(App);

/**
 * Gtm
 */
const gtmConfiguration = {
  /**
   * Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
   */
  id: "GTM-T5KTKPS",

  /**
   * Add url query string when load gtm.js with GTM ID
   * (optional)
   */
  queryParams: {
    gtm_auth: import.meta.env.VITE_GTM_AUTH,
    gtm_preview: import.meta.env.VITE_GTM_PREVIEW,
    gtm_cookies_win: "x",
  },

  /**
   * defaults to false.
   * Script can be set to `defer` to increase page-load-time at the cost of less accurate results
   * (in case visitor leaves before script is loaded, which is unlikely but possible)
   */
  defer: false,

  /**
   * defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie
   * (optional)
   */
  enabled: true,

  /**
   * Whether or not display console logs debugs
   * (optional)
   */
  debug: import.meta.env.MODE === "development",

  /**
   * Whether or not to load the GTM Script
   * (Helpful if you are including GTM manually, but need the dataLayer functionality in your components)
   * (optional)
   */
  loadScript: true,

  /**
   * Pass the router instance to automatically sync with router
   * (optional)
   */
  vueRouter: router,

  /**
   * If router, you can exclude some routes name (case insensitive)
   * (optional)
   */
  ignoredViews: [],
};

/**
 * Sentry
 */
const sentryConfiguration = {
  app,

  debug: false,

  dsn:
    import.meta.env.VITE_SENTRY_ENV != "dev"
      ? "https://6aa706de4ca446ff89fa920dfedb4038@o17409.ingest.sentry.io/6132107"
      : "",

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  /**
   *  Set tracesSampleRate to 1.0 to capture 100%
   * of transactions for performance monitoring.
   * We recommend adjusting this value in production
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
   */
  tracesSampleRate: 0.25,

  /**
   * Capture Replay for 10% of all sessions,
   * plus for 100% of sessions with an error
   */
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  environment: import.meta.env.VITE_SENTRY_ENV || "dev",

  ignoreErrors: [/node_modules/, "fn(vuetify/lib/composables/stack)"],
};

Sentry.init(sentryConfiguration);

/**
 * Router
 */
app.use(router);

app.use(Gtm, gtmConfiguration);

/**
 * i18n
 */
app.use(i18n);

/**
 * Vuetify
 */
const vuetify = createVuetify();
app.use(vuetify);

/**
 * Stores
 */
app.use(store);

/**
 * Dompurify
 */
app.use(DomPurifyDirective);

app.mount("#app");
