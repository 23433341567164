import ConfigInterface from "@/Models/ConfigInterface";
import { state } from "@/Stores/ConfigStore";
import WebgenConfigApi, { ConfigApiHeaders } from "@recommerce/buyback-webgen-config-sdk";
import { throwSentryErr, SentryErr } from "@/Utils/ThrowSentryErr";
import BuybackApiOrder, { OrderConfig } from "@recommerce/buyback-order-sdk";

class ConfigRegistry {
  configs: { [key: string]: ConfigInterface } | undefined;
  configApi: WebgenConfigApi;
  instanceConfig: ConfigInterface | undefined;

  constructor() {
    console.log(".:: ConfigRegistry | Instanciation  ::.");

    const buybackApiHeaders: ConfigApiHeaders = {
      Accept: "application/json, text/javascript, */*; q=0.01",
      "Content-Type": "application/json",
    };

    const apiUrl = import.meta.env.VITE_WEBGEN_API_URL ? import.meta.env.VITE_WEBGEN_API_URL : "./";
    this.configApi = new WebgenConfigApi(apiUrl, buybackApiHeaders);
  }

  /**
   * @name setWithExpiry
   * @param {string} key
   * @param {ConfigInterface | string} value
   * @param {string} ttl
   * @description Set config with TTL in sessionStorage (in case of long session afk or other case)
   * @see https://www.sohamkamani.com/javascript/localstorage-with-ttl-expiry/
   */
  setWithExpiry(key: string, value: ConfigInterface | string, ttl: string) {
    console.log(".:: ConfigRegistry | setWithExpiry ::.");
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };

    sessionStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * @name getWithExpiry
   * @param {string} key
   * @returns item from sessionStorage
   */
  getWithExpiry(key: string): ConfigInterface | string | null {
    console.log(".:: ConfigRegistry | getWithExpiry ::.");

    const itemStr = sessionStorage.getItem(key) as string;

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  /**
   * @name getInstanceConfig
   * @params {string} affiliateId
   * @description Return configuration, matching affiliate identifier.
   */
  async getInstanceConfig(affiliateId: string): Promise<ConfigInterface> {
    console.log(".:: ConfigRegistry | getInstanceConfig ::.");

    return new Promise((resolve, reject) => {
      /**
       * check before request config :
       * - correct instance configuration is currently in store
       *
       * if it's not the case, request the instance config from api
       */
      const storedConfig = this.getWithExpiry("affiliate-config") as ConfigInterface;

      if (storedConfig && storedConfig.instanceId === affiliateId) {
        resolve(storedConfig);
      } else {
        this.configApi
          .getConfig(affiliateId)
          .then(response => {
            const datas = response as unknown as ConfigInterface;
            this.setWithExpiry("affiliate-config", datas, "3600000");
            resolve(datas);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  }

  /**
   * @name getDefaultLocaleFromId
   * @param affiliateId
   * @returns {string} locale or {boolean} if no affiliateConfigs found
   */
  getDefaultLocaleFromId(affiliateId: string): string | boolean {
    console.groupCollapsed(".:: configRegistry | getDefaultLocaleFromId");
    console.log(affiliateId);
    console.groupEnd();

    const configs = this.configs as { [key: string]: ConfigInterface };
    return configs[affiliateId] ? configs[affiliateId].locales[0] : false;
  }

  /**
   * @name getAllowedRoutes
   * @param affiliateId
   * @returns {boolean}
   */
  async getAllowedRoutes(affiliateId: string): Promise<string[]> {
    console.groupCollapsed(".:: configRegistry | getAllowedRoutes");
    console.log(affiliateId);
    console.groupEnd();

    let allowedRoutes;

    return new Promise((resolve, reject) => {
      /**
       * check before request config :
       * - correct instance configuration is currently in store
       *
       * if it's not the case, request the instance config from api
       */
      if (affiliateId === state.instanceId && state.whitelistRoutes) {
        resolve(state.whitelistRoutes);
      } else {
        this.getInstanceConfig(affiliateId)
          .then(response => {
            const instanceConfig: ConfigInterface = response;
            allowedRoutes = instanceConfig.whitelistRoutes;

            if (allowedRoutes === undefined) {
              allowedRoutes = ["all"];
            }

            resolve(allowedRoutes);
          })
          .catch(error => {
            throwSentryErr(error as SentryErr);
            reject(error);
          });
      }
    });
  }

  /**
   * @name getCssFromTheme
   * @param {string} theme
   */
  async getCssFromTheme(theme: string): Promise<string> {
    console.groupCollapsed(".:: configRegistry | getCss");
    console.log(theme);
    console.groupEnd();

    let themeToReturn = "";

    /**
     * Check if a theme is already stored.
     */
    const storedTheme = this.getWithExpiry("affiliate-theme") as string | undefined;

    if (storedTheme) {
      const storedThemeName = storedTheme.match(/\[theme=(.*)\]/) as RegExpMatchArray;

      /**
       * the stored theme is the one we need ?...
       */
      if (storedThemeName[1] === theme) {
        themeToReturn = storedTheme;
      }
    }

    if (themeToReturn.length === 0) {
      const response = await this.configApi.getCss(theme);
      this.setWithExpiry("affiliate-theme", response, "3600000");
      themeToReturn = response;
    }

    return themeToReturn;
  }

  /**
   * @name getOrderConfig
   */
  async getOrderConfig(orderId: string): Promise<OrderConfig> {
    console.log(".:: ConfigRegistry | getOrderConfig ::.");
    const headers = {
      "Content-Type": "application/vnd.api-buyback.v4+json",
      Accept: "application/json, text/javascript, */*; q=0.01",
      Authorization: "",
      "Content-Language": "fr",
      "Accept-Language": "fr-FR",
    };

    const url = import.meta.env.VITE_BUYBACK_ORDER_API_URL;
    const _buybackAPI = new BuybackApiOrder(url as string, headers);
    try {
      const conf = await _buybackAPI.getOrderConfig(orderId);
      return conf;
    } catch (error) {
      const err = error as unknown as SentryErr;
      const status = err.status ? err.status.toString() : "500";
      throw status;
    }
  }

  /**
   * @name getPathFronOrderConfig
   */
  async getPathFromOrderConfig(location: string, orderId: string): Promise<string | null> {
    console.log(".:: ConfigRegistry | getPathFromOrderConfig ::.");

    try {
      const orderConfig = await this.getOrderConfig(orderId);
      const language = orderConfig.customerLanguage.split("_")[0];
      return `/${orderConfig.instanceName}/${language}/${location}/${orderId}`;
    } catch (error) {
      throw error as string;
    }
  }
}

export default new ConfigRegistry();
