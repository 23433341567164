// For more info, see https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "en",
  legacy: false,
  globalInjection: true,
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "en",
  silentTranslationWarn: true,
  messages: {},
  warnHtmlMessage: false, // @see https://stackoverflow.com/a/69994464
});

const loadedLanguages: string[] = [];

/**
 * @name setLocale
 * @param locale
 * @returns {string} locale
 */
function setLocale(locale: string) {
  i18n.global.locale.value = locale;
  // Set DOM lang attr (usefull for CSS)
  document.documentElement.lang = locale;
  return locale;
}

/**
 * @name loadLocaleMessagesAsync
 * @param locale
 * @returns
 */
export function loadLocaleMessagesAsync(locale: string) {
  console.groupCollapsed(".:: i18n.ts | Methods | loadLocaleMessagesAsync | locale : %s  ::.", locale);
  console.log(locale);
  console.groupEnd();

  if (loadedLanguages.length > 0 && i18n.global.locale.value === locale) {
    return Promise.resolve(setLocale(locale));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    return Promise.resolve(setLocale(locale));
  }

  return fetch(`/locales/${locale}.json`)
    .then(response => response.json())
    .then(messages => {
      i18n.global.setLocaleMessage(locale, messages);
      loadedLanguages.push(locale);
      return Promise.resolve(setLocale(locale));
    });
}

export default i18n;
