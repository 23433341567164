/**
 * Libs
 */
import { createStore } from "vuex";

/**
 * Stores
 */
import { OrderStore } from "./BuybackOrder";
import { StateManager } from "./StateManager";
import { ConfigStore } from "./ConfigStore";

/**
 * @name store
 */
const store = createStore({
  modules: {
    OrderStore,
    StateManager,
    ConfigStore,
  },
});

export default store;
